import React from "react";
import BaseLayout from "@wc/ui/components/layouts";
import { WCNavbar } from "@wc/domain/nav";

/**
 * @param {object} props The component props
 * @param {React.ReactElement} props.children The nested page to pass through
 * @returns {React.ReactElement} A layout with a Navbar
 */
export function LayoutWithNavbar({ children }) {
  return (
    <BaseLayout>
      <WCNavbar text="WINCARDS" />
      <div className="mt-2">{children}</div>
    </BaseLayout>
  );
}

/**
 * A layout for a non-full width page
 * @param {object} props The component props
 * @param {React.ReactElement} props.children The nested page to pass through
 * @returns {React.ReactElement} A skinny page layout with a header
 */
export function CenteredPageLayout({ children }) {
  return (
    <LayoutWithNavbar>
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">{children}</div>
    </LayoutWithNavbar>
  );
}
