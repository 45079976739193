import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import { BuildConfig } from "@wc/domain/config";

let FSInstance;

/**
 *
 */
class FirebaseService {
  #auth;
  #googleProvider;

  /**
   * Creates a FirebaseService, for interacting with the firebase client.
   * Ideally, we have a set of "services" for different functions within the application
   */
  constructor() {
    const config = {
      apiKey: BuildConfig.FIREBASE_API_KEY,
      authDomain: BuildConfig.FIREBASE_AUTH_DOMAIN,
      projectId: BuildConfig.FIREBASE_PROJECT_ID,
      appId: BuildConfig.FIREBASE_APP_ID,
    };

    initializeApp(config);
    this.#auth = getAuth();
    this.#googleProvider = new GoogleAuthProvider();
  }

  /**
   * Initiates Google sign-in popup flow.
   * @returns {Promise<import('firebase/auth').User>} Promise resolving to the signed-in user
   * @throws {Error} If sign-in fails
   */
  async signInWithGoogle() {
    try {
      const result = await signInWithPopup(this.#auth, this.#googleProvider);
      return result.user;
    } catch (error) {
      console.error(error.code);
      throw error;
    }
  }

  /**
   * Signs out the current user.
   * @returns {Promise<void>}
   */
  async signOut() {
    await signOut(this.#auth);
  }

  /**
   * Gets the currently signed-in user.
   * @returns {import('firebase/auth').User | null} The current user or null if not signed in
   */
  getCurrentUser() {
    return this.#auth.currentUser;
  }

  /**
   * Sets up an observer for authentication state changes.
   * @param {(user: import('firebase/auth').User | null) => void} callback Function called when auth state changes
   * @returns {() => void} Unsubscribe function
   */
  onAuthStateChanged(callback) {
    return this.#auth.onAuthStateChanged(callback);
  }
}

/**
 * Gets the singleton instance of FirebaseService.
 * Creates a new instance if one doesn't exist.
 * @returns {FirebaseService} The singleton instance
 */
function getInstance() {
  if (!FSInstance) {
    FSInstance = new FirebaseService();
  }
  return FSInstance;
}

export default getInstance();
