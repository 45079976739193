import React from "react";
import { Card, CardHeader, CardBody, CardFooter } from "@nextui-org/react";

/**
 *
 * @param {object} props The component props
 * @param {React.ReactElement} props.children Nested card body
 * @returns {React.ReactElement} A card component
 */
export default function SingleCardOnPage({ children }) {
  return <Card className="p-3">{children}</Card>;
}
