import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import "./index.css";
import "@wc/ui/locales";
import { AuthProvider } from "@wc/domain/authn";

import WCRouter from "@wc/pages";

/**
 * This is the entry point for the client-side application.
 * @returns {void}
 * @throws {Error} If the root element is not found in the DOM
 */
function main() {
  const container = document.getElementById("root");
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <AuthProvider>
        <NextUIProvider>
          <I18nextProvider i18n={i18next}>
            <RouterProvider router={WCRouter} />
          </I18nextProvider>
        </NextUIProvider>
      </AuthProvider>
    </React.StrictMode>,
  );
}

main();
