import { useEffect } from "react";

/**
 * Use this in pages to update the title of the document.
 * @param {string} title The page title to use.
 */
export default function usePageTitle(title) {
  useEffect(() => {
    const previousTitle = document.title;
    document.title = `${title} | WINCARDS`;
    return () => {
      document.title = previousTitle;
    };
  }, [title]);
}
