import React from "react";
import { useTranslation } from "react-i18next";
import CheckboxInput from "@wc/domain/components/win-card/checkbox-input";
import {
  getThisWeeksGoalsLocal,
  saveThisWeeksGoalsLocal,
} from "@wc/domain/components/win-card/win-card-service";

/**
 * @param {object} props The component props
 * @param {Date} props.date The date to reference
 * @returns {React.ReactElement} A list of checkbox inputs
 */
export default function InputList({ date }) {
  const { t } = useTranslation();

  const data = getThisWeeksGoalsLocal(date);

  const [goals, setGoals] = React.useState(data);
  const goalRefs = React.useRef([]);

  /**
   * Handles changes to input values and saves to localStorage
   * @param {boolean} checked - Whether the user checked the goal or not
   * @param {string} value - The new value of the input
   * @param {number} index - The index of the changed input
   */
  const handleChange = (checked, value, index) => {
    const newGoals = [...goals];
    newGoals[index] = { completed: checked, text: value };
    setGoals(newGoals);
    saveThisWeeksGoalsLocal(date, newGoals);
  };

  /**
   * Focuses an input at the specified index
   * @param {number} index - The index of the input to focus
   */
  const focusInput = React.useCallback(
    (index) => {
      if (index >= 0 && index < goals.length) {
        setTimeout(() => {
          const ref = goalRefs.current[index]?.current;
          const inputElement = ref?.querySelector("input") || ref?.base || ref;

          if (inputElement) {
            inputElement.focus();
            inputElement.select();
          }
        }, 10);
      }
    },
    [goals.length],
  );

  /**
   * Handles Enter key press on inputs and saves new state
   * @param {number} index - The index of the input where Enter was pressed
   */
  const handleEnter = (index) => {
    if (goals.length >= 5 || index < goals.length - 1) {
      focusInput(index + 1);
    } else if (goals.length < 5) {
      const newGoals = [...goals, { done: false, text: "" }];
      setGoals(newGoals);
      saveThisWeeksGoalsLocal(date, newGoals);
    }
  };

  /**
   * Handles Tab key navigation
   * @param {number} index - The index of the current input
   * @param {boolean} isShiftKey - Whether Shift key was pressed with Tab
   */
  const handleTab = (index, isShiftKey) => {
    const nextIndex = isShiftKey ? index - 1 : index + 1;
    if (nextIndex >= 0 && nextIndex < goals.length) {
      setTimeout(() => {
        focusInput(nextIndex);
      }, 0);
    }
  };

  React.useEffect(() => {
    goalRefs.current = goalRefs.current.slice(0, goals.length);
    while (goalRefs.current.length < goals.length) {
      goalRefs.current.push({ current: null });
    }

    const lastIndex = goals.length - 1;
    if (lastIndex >= 0) {
      focusInput(lastIndex);
    }
  }, [goals.length, focusInput]);

  return (
    <div className="mx-auto w-full p-4">
      <div className="mb-4 flex flex-col items-center justify-between gap-4">
        {goals.map((goal, index) => {
          const placeholder =
            index === 0 ? t("first-placeholder") : t("nth-placeholder");

          const isLast = index === goals.length - 1;
          const isFinal = goals.length === 5;

          return (
            <CheckboxInput
              inputRef={goalRefs.current[index]}
              key={`checkbox-input-${index}`}
              index={index}
              isLast={isLast}
              isFinal={isFinal}
              checked={goal.completed}
              valueText={goal.text}
              placeholderText={placeholder}
              onChange={handleChange}
              onEnter={handleEnter}
              onTab={handleTab}
            />
          );
        })}
      </div>
    </div>
  );
}
