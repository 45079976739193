import React from "react";
import usePageTitle from "@wc/ui/hooks/page-title";
import { LoginCard } from "@wc/domain/authn";

/**
 * @returns {React.ReactElement} The Login Page
 */
export default function Login() {
  usePageTitle("Login");
  return <LoginCard />;
}
