/**
 * This will read environment variables for what was set at build time.
 * It works for now, we'll just need different builds for different environments.
 * @param {string} envvar The name of the build environment variable
 * @param {string} defaultValue A default value to use if the
 * @returns {string} The unprocessed envVar
 */
function getFromBuildEnv(envvar, defaultValue) {
  if (envvar in import.meta.env) {
    return import.meta.env[envvar];
  }
  return defaultValue;
}

/**
 * The the config reads environment at build time.
 * @type {{
 *   VERSION: string,
 *   FIREBASE_API_KEY: string,
 *   FIREBASE_PROJECT_ID: string,
 *   FIREBASE_APP_ID: string
 *   FIREBASE_AUTH_DOMAIN: string
 * }}
 *
 * The defaults here are for a development wincardsapp project so that the system can run
 * for all local enviornments. They are overrided in the production build.
 */
export const BuildConfig = {
  VERSION: getFromBuildEnv("WC_BUILD_VERSION", `${__GIT_SHA__}-dev`),
  FIREBASE_API_KEY: getFromBuildEnv(
    "WC_BUILD_FIREBASE_API_KEY",
    "AIzaSyCWSQnJdXQ2Y86-fV7UtNsUMf1YQ6ojnoA",
  ),
  FIREBASE_PROJECT_ID: getFromBuildEnv(
    "WC_BUILD_FIREBASE_PROJECT_ID",
    "wincardsapp-dev",
  ),
  FIREBASE_APP_ID: getFromBuildEnv(
    "WC_BUILD_FIREBASE_APP_ID",
    "1:613552709930:web:9164df50dab4840194fe53",
  ),
  FIREBASE_AUTH_DOMAIN: getFromBuildEnv(
    "WC_BUILD_FIREBASE_AUTH_DOMAIN",
    "wincardsapp-dev.firebaseapp.com",
  ),
};
