import { formatDateInternal } from "@wc/domain/dates";

/**
 * @typedef { import("@wc/domain/models").Goal } Goal
 */

/**
 * @enum {symbol}
 * Names of "tables" in our data setup
 */
const TablesEnum = Object.freeze({
  GOALS: Symbol("goals"),
});

/**
 * The model for how we store individual goals
 * @typedef {object} GoalsLocalStorageModel
 * @property {boolean} completed Is the goal completed?
 * @property {string} text The goal text
 */

/**
 * A string date representation to the associated goals for that date
 * @typedef {{[key: string]: GoalsLocalStorageModel[]}} GoalsLocalStorageTable
 */

/**
 * @returns {GoalsLocalStorageTable} The contents of the table
 */
function loadGoalsLocalTable() {
  const goals = localStorage.getItem(TablesEnum.GOALS.description);
  if (goals === null) {
    return {};
  }
  return JSON.parse(goals);
}

/**
 * @param {GoalsLocalStorageTable} allGoals The goals data to save
 * @returns {void}
 */
function saveGoalsLocalTable(allGoals) {
  localStorage.setItem(TablesEnum.GOALS.description, JSON.stringify(allGoals));
}

/**
 * Retrieve goals from local storage
 * @param {Date} date The date to search for
 * @returns {Goal[]} The list of goals for the week
 */
export function getThisWeeksGoalsLocal(date) {
  const dateKey = formatDateInternal(date);
  const allGoals = loadGoalsLocalTable();

  const weekGoals = allGoals[dateKey];

  return weekGoals ?? [{ completed: false, text: "" }];
}

/**
 * Save goals for a date
 * @param {Date} date the date to save under
 * @param {Goal[]} goals The goals to save
 * @returns {Goal[]} The saved goals
 */
export function saveThisWeeksGoalsLocal(date, goals) {
  const dateKey = formatDateInternal(date);
  const allGoals = loadGoalsLocalTable();

  allGoals[dateKey] = goals;
  saveGoalsLocalTable(allGoals);

  return goals;
}
