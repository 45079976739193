import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { CenteredPageLayout } from "@wc/domain/layouts";

import Home from "@wc/pages/home";
import Login from "@wc/pages/home/login";

const WCRouter = createBrowserRouter([
  {
    path: "/",
    element: (
      <CenteredPageLayout>
        <Home />
      </CenteredPageLayout>
    ),
  },
  {
    path: "/login",
    element: (
      <CenteredPageLayout>
        <Login />
      </CenteredPageLayout>
    ),
  },
]);

export default WCRouter;
