import React from "react";

import FirebaseService from "@wc/adapters/firebase";

/**
 * @typedef { import("../models").AuthnUser } AuthnUser
 */

/**
 * We need access to the user, whether they are logged in,
 * And then actions that can log them in or out.
 * @typedef {object} AuthContextData
 * @property {() => AuthnUser} getCurrentUser Access to a user if they exist, errors otherwise
 * @property {() => boolean} isLoggedIn Whether any users are logged in at the moment
 * @property {() => void} logout Log the current user out
 * @property {() => boolean} loginOAuth Log in through an oauth method
 */

// --- Service Functions -------------------------

/**
 * @returns { AuthnUser |  null } The user if they exist
 */
function getCurrentUser() {
  const user = FirebaseService.getCurrentUser();
  if (user) {
    return {
      id: user.uid,
      displayName: user.displayName,
      email: user.email,
    };
  }
  return null;
}

/**
 * @returns {boolean} A successful logout or not
 */
async function logout() {
  try {
    await FirebaseService.signOut();
    return true;
  } catch (error) {
    console.error("unable to log out", error);
    return false;
  }
}

/**
 * Use google to log in.
 * @returns {boolean} A successful login
 */
async function loginGoogle() {
  try {
    await FirebaseService.signInWithGoogle();
  } catch (error) {
    console.error("Unable to log in", error);
    return false;
  }
  return true;
}

// --- Context & Provider ------------------------

/**
 * @param {AuthnUser | null} user The user or null
 * @param { Function } setUser A function to set the user, from a setState
 * @param { boolean } loading Whether we should be loading or not
 * @param { Function } setLoading A function to load the user
 * @returns {AuthContextData} Information and actions to work with auth
 */
function createAuthContextData(user, setUser, loading, setLoading) {
  return {
    isLoggedIn: () => {
      return user !== null;
    },
    getCurrentUser: () => {
      if (user) {
        return user;
      } else {
        throw new Error("No user available");
      }
    },
    logout: async () => {
      const success = await logout();
      if (success) {
        setUser(null);
      }
    },
    loginOAuth: async () => {
      const success = await loginGoogle();
      if (success) {
        setUser(getCurrentUser());
      }
      return success;
    },
  };
}

export const AuthContext = React.createContext(null);

/**
 *
 * @param {object} props Props for the context
 * @param {React.Element} props.children Children that need the context's scope
 * @returns {React.Element} The AuthContext
 */
export default function AuthProvider({ children }) {
  const [user, setUser] = React.useState(null);
  /** @type { boolean }  */
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!user) {
      const currentUser = getCurrentUser();
      if (currentUser) {
        setUser(currentUser);
        setLoading(false);
      }
    }
  }, [user]);

  const contextData = createAuthContextData(user, setUser, loading, setLoading);

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
}
