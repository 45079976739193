import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
} from "@nextui-org/react";
import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as WCLogo } from "@wc/ui/assets/logo.svg";
import { ReactComponent as CupIcon } from "@wc/ui/assets/icons/cup.svg";
import { ReactComponent as MoonIcon } from "@wc/ui/assets/icons/moon.svg";
import { ReactComponent as PeopleIcon } from "@wc/ui/assets/icons/people.svg";
import { ReactComponent as Building2Icon } from "@wc/ui/assets/icons/building-2.svg";
import { ReactComponent as MenuBoardIcon } from "@wc/ui/assets/icons/menu-board.svg";

import { BuildConfig } from "@wc/domain/config";
import { useAuth } from "@wc/domain/authn";

/**
 *
 * @param {object} props The component props
 * @param {string} [props.text] The text to display in the navbar
 * @returns {React.ReactElement} A navbar component
 */
export default function WCNavbar({ text = "" }) {
  const authContext = useAuth();

  return (
    <Navbar maxWidth="full" shouldHideOnScroll>
      <NavbarBrand>
        <Link to="/">
          <WCLogo role="img" aria-label={text} />
        </Link>
      </NavbarBrand>
      <NavbarContent className="hidden gap-4 sm:flex" justify="center">
        <NavbarItem className="flex gap-2 font-bold">
          <MenuBoardIcon role="img" aria-label="this week" />
          <span>This week</span>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent className="gap-4 sm:flex" justify="end">
        {!authContext.isLoggedIn() && (
          <>
            <NavbarItem>
              <Link to="/login">Log In</Link>
            </NavbarItem>
            <NavbarItem>
              <Link to="/login">
                <Button color="primary">Sign Up</Button>
              </Link>
            </NavbarItem>
          </>
        )}
        {authContext.isLoggedIn() && (
          <>
            <NavbarItem>
              <Button isIconOnly variant="light">
                <CupIcon role="img" aria-label="achievements" />
              </Button>
            </NavbarItem>
            <NavbarItem>
              <Button
                isIconOnly
                variant="light"
                onClick={() => {
                  const oldPref = localStorage.getItem("darkMode") ?? "light";
                  const newPref = oldPref === "dark" ? "light" : "dark";
                  localStorage.setItem("darkMode", newPref);
                  document.documentElement.classList.toggle(
                    "dark",
                    newPref === "dark",
                  );
                }}
              >
                <MoonIcon role="img" aria-label="dark mode preference" />
              </Button>
            </NavbarItem>
            <Dropdown>
              <NavbarItem>
                <DropdownTrigger>
                  <Avatar
                    isBordered
                    radius="lg"
                    name={authContext.getCurrentUser().displayName}
                    className="cursor-pointer"
                  />
                </DropdownTrigger>
              </NavbarItem>
              <DropdownMenu
                aria-label="User Menu"
                className="w-[340px]"
                itemClasses={{
                  base: "gap-4",
                }}
                disabledKeys={["version"]}
              >
                <DropdownSection title="Me">
                  <DropdownItem
                    key="profile"
                    href="/profile"
                    description="My first dropdown"
                  >
                    Profile
                  </DropdownItem>
                </DropdownSection>
                <DropdownSection title="Team">
                  <DropdownItem
                    key="manage-teams"
                    href="/manage-teams"
                    description="Manage teams"
                    startContent={
                      <PeopleIcon role="img" aria-label="profile" />
                    }
                  >
                    Manage teams
                  </DropdownItem>
                </DropdownSection>
                <DropdownSection title="Org">
                  <DropdownItem
                    key="manage-organization"
                    href="/manage-organization"
                    description="Manage organization"
                    startContent={
                      <Building2Icon role="img" aria-label="profile" />
                    }
                  >
                    Manage Organization
                  </DropdownItem>
                </DropdownSection>
                <DropdownItem key="version" className="flex justify-center">
                  {BuildConfig.VERSION}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </>
        )}
      </NavbarContent>
    </Navbar>
  );
}
