import React from "react";
import { CardHeader, CardBody, CardFooter } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import SingleCardOnPage from "@wc/ui/components/single-card-on-page";
import { formatDate, lastMonday } from "@wc/domain/dates";
import InputList from "@wc/domain/components/win-card/input-list";

/**
 * The singlecardonpage component is a wrapper for a nextui card, just
 * use raw nextui components to fill it out.
 * @returns {React.ReactElement} A Editable WinCard
 */
export default function WinCardEditable() {
  const { t } = useTranslation();
  const monday = formatDate(lastMonday());

  return (
    <SingleCardOnPage>
      <CardHeader className="mx-auto flex max-w-md justify-center">
        <h1 className="flex flex-wrap justify-center text-2xl font-bold sm:text-3xl">
          <span>{t("weekOf")}</span>
          <span className="text-primary ml-2">{monday}</span>
        </h1>
      </CardHeader>
      <CardBody className="flex justify-center">
        <div className="mx-auto mb-2 max-w-md px-4 text-center font-bold sm:px-6 lg:px-8">
          <p>{t("crush")}</p>
          <p>{t("add-i")}</p>
        </div>
        <InputList date={monday} />
      </CardBody>
    </SingleCardOnPage>
  );
}
