import React from "react";

/**
 * Layout for the website. Should wrap every page.
 * @param {object} props The component props
 * @param {React.ReactElement} props.children The nested page to pass through
 * @returns {React.ReactElement} The full page
 */
export default function BaseLayout({ children }) {
  return <div className="w-full">{children}</div>;
}
